import React from 'react';
import axios from 'axios';
import './LoginPage.css';

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      nameTaken: false,
      wrongCredentials: false,
      unknownError: false
    }

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
  }

  handleLogin(event) {
    axios({
      method: 'post',
      url: "https://dotsnboxes.com/api/login.php",
      headers: { 'content-type': 'application/json'},
      data: {username: this.state.username, password: this.state.password},
    }).then(response => {
      if (response.data.status === "ERR_INVALID_CREDS") {
        this.setState({...this.state,
          nameTaken: false,
          wrongCredentials: true,
          unknownError: false
        });
      } else if (response.data.status === "LOGIN_SUCCESS") {
        this.props.setAccount(
          response.data.username,
          response.data.role,
          response.data.elo
        );
        window.location.href = "/";
      } else {
        this.setState({...this.state,
          nameTaken: false,
          wrongCredentials: false,
          unknownError: true
        })
      }
    })
  }

  handleSignup(event) {
    axios({
      method: 'post',
      url: "https://dotsnboxes.com/api/signup.php",
      headers: { 'content-type': 'application/json'},
      data: {username: this.state.username, password: this.state.password},
    }).then(response => {
      if (response.data.status === "ERR_NAME_TAKEN") {
        this.setState({...this.state,
          nameTaken: true,
          wrongCredentials: false,
          unknownError: false
        });
      } else if (response.data.status === "ACC_CREATED") {
        this.props.setAccount(
          response.data.username,
          response.data.role,
          response.data.elo
        );
        window.location.href = "/";
      } else {
        this.setState({...this.state,
          nameTaken: false,
          wrongCredentials: false,
          unknownError: true
        })
      }
    })
  }

  handleUsernameChange(event) {
    this.setState({...this.state, username: event.target.value.slice(0, 19)});
    event.target.value = event.target.value.slice(0, 19);
  }

  handlePasswordChange(event) {
    this.setState({...this.state, password: event.target.value});
  }

  render() {
    const nameTaken = (
      <div className="LoginError">
        <h3>That name is taken.</h3>
        <h3>Please try again</h3>
      </div>
    )

    const invalidCreds = (
      <div className="LoginError">
        <h3>Invalid Username/Password</h3>
        <h3>Please try again</h3>
      </div>
    )

    const unknownError = (
      <div className="LoginError">
        <h3>An Unkown Error Occurred</h3>
        <h3>Please try again</h3>
      </div>
    )

    return (
      <div className="LoginPage">
        <div className="Login">
          <h1>Login/Signup</h1>
          {this.state.nameTaken ? nameTaken : ""}
          {this.state.wrongCredentials ? invalidCreds : ""}
          {this.state.unknownError ? unknownError : ""}
          <input type="text" placeholder="Username" onInput={this.handleUsernameChange}/>
          <input type="password" placeholder="Password" onInput={this.handlePasswordChange}/>
          <div className="LoginButtons">
            <button onClick={this.handleLogin}><div><h3>Log In</h3></div></button>
            <button onClick={this.handleSignup}><div><h3>Sign Up</h3></div></button>
          </div>
        </div>
      </div>
    )
  }
}
