import React from 'react';
import './Navbar.css';
import logo from './DotsnBoxeslogoStraightLine.png';

export class Navbar extends React.Component {
  render() {
    return (
      <nav className="header">
          <div id="headerLogo">
              <a className="pageLink" href="./"><img id='logo' src={logo} alt="Homepage" /></a>
           </div>
          <div id="navButtons">
            <ul>
              <a className="pagelink" href="Play">
                   <li>Play</li>
              </a>

              <a className="pagelink" href="Learn">
                  <li>Learn</li>
              </a>

              <a className="pagelink" href="Leaderboards">
                  <li>Leaderboards</li>
              </a>
              { this.props.username === null ?
                <a className="pagelink" href="Login">
                  <li>Log in</li>
                </a>
                :
                <a className="pagelink" href="/" onClick={this.props.clearStorage}>
                  <li>Log Out</li>
                </a>
              }
            </ul>
          </div>
      </nav>
    )
  }
}
