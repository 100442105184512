import React from 'react';
import './UserCard.css';

export class UserCard extends React.Component {
  constructor(props) {
    super(props);
  }

  determineHighlight() {
    if (this.props.thisPlayer === "Player One" && this.props.side === "Left") {
      if (this.props.clientsTurn) {
        return ("TurnHighlight");
      } else {
        return ("NoHighlight");
      }
    } else if (this.props.thisPlayer === "Player Two" && this.props.side === "Right") {
      if (this.props.clientsTurn) {
        return ("TurnHighlight");
      } else {
        return ("NoHighlight");
      }
    }
    if (!this.props.clientsTurn) {
      return ("TurnHighlight");
    } else {
      return ("NoHighlight");
    }
  }

  render() {
    return (
      <div className={this.props.side} id={this.determineHighlight()}>
        <div className="PlayerInfo">
          <h3 className={this.props.role === "Admin" ? "GoldName" : ""}>{this.props.username != null ? this.props.username : "You"}</h3>
          <h5>Elo: {this.props.elo != null ? this.props.elo : "1000"}</h5>
        </div>
        <div className={this.props.side === 'Left' ? 'Red' : "Blue"}>
        </div>
      </div>
    );
  }
}
