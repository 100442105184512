import React from 'react';

export class YLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: "none",
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseExit = this.handleMouseExit.bind(this);
  }

  handleMouseEnter(event) {
    if (!this.props.isClicked) {
      this.setState({
        ...this.state,
        color: 'yellow'
      })
    }
  }

  handleMouseExit(event) {
    if (!this.props.isClicked) {
      this.setState({
        ...this.state,
        color: 'none'
      })
    }
  }

  render() {
    return (
      <div
        className="YLine"
        id={(this.state.color === "yellow" && !this.props.isClicked) ? "yellow" : this.props.color}
        onMouseOver={this.handleMouseEnter}
        onMouseOut={this.handleMouseExit}
        onClick={(e) => this.props.onClick(e, this.props.position[0], this.props.position[1], this.props.type, false)}>
      </div>
    );
  }
}
