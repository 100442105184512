import React from 'react';
import {Link} from 'react-router-dom';
import './OpenGameBoard.css';

export class OpenGameBoard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {games: {}}

    this.webSocket = new WebSocket((process.env.NODE_ENV === 'development' ? "ws://localhost:3001" : "wss://dotsnboxes.com:8080"))

    this.webSocket.onopen = () => {
      let connectionRequest = {type: "Get Games"};
      this.webSocket.send(JSON.stringify(connectionRequest))
    }

    this.webSocket.addEventListener("message", (msg) => {
      let message = JSON.parse(msg.data);
      if (message.type === "Game List") {
        this.setState({games: message.games})
      }
    })

    this.convertGame = this.convertGame.bind(this);
  }

  convertGame(gameId, index) {
    return (
      <Link to="/DotGame" key={index} state={{
                          boardSize: this.state.games[gameId].boardSize,
                          againstAI: false,
                          ranked: this.state.games[gameId].ranked,
                          gameId: gameId}}>
        <div className={(index+1) % 2 ? "OpenGameEntry Odd" : "OpenGameEntry Even"}>
          <h3 className="Type">{this.state.games[gameId].boardSize}</h3>
          <h3 className="Ranked">{this.state.games[gameId].ranked ? "Yes": "No"}</h3>
          <h3 className="Opponent">{this.state.games[gameId].userOne}</h3>
        </div>
      </Link>
    )
  }

  render() {
    return (
      <div className="OpenGameBoard">
        <div className="BoardHeader">
          <h3>Open Games</h3>
        </div>
          {Object.getOwnPropertyNames(this.state.games).length === 0 ?
              <div id="NoGames">
                <h3 style={{opacity: "70%"}}>
                  There are currently no open games
                </h3>
              </div>
            : <div className="OpenGameEntry Even Descriptors">
                <h3 className="Type">Board Size</h3>
                <h3 className="Ranked">Ranked</h3>
                <h3 className="Opponent">Opponent</h3>
              </div>
          }
          {Object.getOwnPropertyNames(this.state.games).map((gameId, index) => (
            this.convertGame(gameId, index)
          ))}



      </div>
    );
  }
}
