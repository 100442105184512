import React from 'react';
import {OpenGameBoard} from './opengameboard/OpenGameBoard';
import {CreateGame} from './creategame/CreateGame';


export class PlayPage extends React.Component {
  render() {
    return (
      <div>
        <OpenGameBoard />
        <CreateGame />
      </div>
    );
  }
}
