import React from 'react';
import './Score.css';

export class Score extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="Score">
        <p className="ScoreNumber R">{this.props.p1Score}</p>
        <div className="ScoreSeperator"></div>
        {/*<p className="X"> X </p>*/}
        <p className="ScoreNumber B">{this.props.p2Score}</p>
      </div>
    );
  }
}
