import React from 'react';
import './GameBoard.css';
import {Dot} from './dot/Dot';
import {XLine} from './xline/XLine';
import {YLine} from './yline/YLine';
import {Box} from './box/Box';

// Functions for Creating the Game board Array
// This array is how this component keeps track of changes to the game state
function getIterations(boardSize) {
  switch(boardSize) {
    case "3x3": return 3;
    case "5x5": return 5;
    case "9x9": return 9;
    case "13x13": return 13;
    default: return 0;
  }
}

function createBoard(iterations) {
  let board = [];
  if (iterations === 0) {
    return "error";
  }
  board.push(createHorizontalRow(0, iterations));
  for (let i = 0; i < iterations; i++) {
    board.push(createVerticalRow((2*i)+1, iterations));
    board.push(createHorizontalRow((2*i)+2, iterations));
  }
  return board;
}

function createHorizontalRow(currentRow, iterations) {
  let row=[];
  row.push({
    type: "Dot"
  })
  for (let i = 0; i < iterations; i++) {
    row.push({
      type: "XLine",
      position: [currentRow, ((2*i)+1)],
      color: "none",
      isClicked: false
    })
    row.push({
      type:"Dot"
    })
  }
  return row;
}

function createVerticalRow(currentRow, iterations) {
  let row = [];
  row.push({
    type: "YLine",
    position: [currentRow, 0],
    color: "none",
    isClicked: false
  })
  for (let i = 0; i < iterations; i++) {
    row.push({
      type: "Box",
      position: [currentRow, ((2*i)+1)],
      color: "none"
    })

    row.push({
      type:"YLine",
      position: [currentRow, ((2*i)+2)],
      color: "none",
      isClicked: false
    })
  }
  return row;
}

export class GameBoard extends React.Component {
  constructor(props) {
    super(props);
    this.iterations = getIterations(this.props.boardSize);
    this.board = createBoard(this.iterations);
    this.aisTurn = false;
    this.lastClicked = null;
    this.beforeLastClicked = null;
    this.playerOneScore= 0;
    this.playerTwoScore= 0;

    this.handleClick = this.handleClick.bind(this);

    if (this.props.webSocket !== null) {
      this.props.webSocket.addEventListener("message", (msg) => {
        let message = JSON.parse(msg.data);
        if (message.type === "Game Found") {
          if (message.playerOne === "You") {
            this.props.swapTurn()
          }
        }

        if (message.type === "Opponent Move") {
          this.handleClick("N/A", message.yPosition, message.xPosition, message.lineType, true);
        }
      })
    }
  }

  convertRow(boardRow) {
    return <div className="BoardRow">{boardRow.map(element => (
      this.convertElement(element)
    ))}</div>
  }

  convertElement(element) {
    if (element.type === 'Dot') {
      return <Dot />
    } else if (element.type === 'Box') {
      return <Box
              position={element.position}
              color={element.color}/>;
    } else if (element.type === 'XLine') {
      return <XLine
                position={element.position}
                color={element.color}
                isClicked={element.isClicked}
                onClick={this.handleClick}
                type={'XLine'}/>;
    } else if (element.type === 'YLine') {
      return <YLine
                position={element.position}
                color={element.color}
                isClicked={element.isClicked}
                onClick={this.handleClick}
                type={'YLine'}/>;
    }
  }

  async handleClick(event, yPosition, xPosition, type, isFromOpponent) {
    if (isFromOpponent || this.props.clientsTurn) {
      if (!this.board[yPosition][xPosition].isClicked) {
        this.updateLine(yPosition, xPosition);
        let changeTurn = this.checkBoxCompletions(yPosition, xPosition, this.board[yPosition][xPosition].type);
        if (changeTurn) {
          this.props.swapTurn()
        }
        if (this.props.againstAI) {
          // This is neccessary to allow the state to set
          // I'm just as beffuddled you are
          const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
          await delay(0)
          if (changeTurn) {
            this.aisTurn = !this.aisTurn
          }
          this.aiTurn();
        }
        if (!isFromOpponent && !this.props.againstAI) {
          this.props.webSocket.send(JSON.stringify({
            type: "Make Move",
            yPosition: yPosition,
            xPosition: xPosition,
            lineType: type,
            gameId: this.props.gameId
          }))
        }
        this.setState({...this.state});
      }
    }
  }

  updateLine(yPosition, xPosition) {
    this.board[yPosition][xPosition] = {
      ...this.board[yPosition][xPosition],
      color: 'green',
      isClicked: true
    }
    if (this.lastClicked != null) {
      this.board[this.lastClicked[0]][this.lastClicked[1]] = {
        ...this.board[this.lastClicked[0]][this.lastClicked[1]],
        color: 'black'
      }
    }
    this.beforeLastClicked = this.lastClicked;
    this.lastClicked = [yPosition, xPosition];
  }

  checkBoxCompletions(yPosition, xPosition, type) {
    let changeTurn = true;
    let scored = 0;
    if (type === "XLine") {
      if (!(yPosition === 0)) {
        if (this.boxAboveComplete(yPosition, xPosition)) {
          this.board[yPosition-1][xPosition].color = this.selectColor();
          this.addToScore()
          scored++;
          changeTurn = false;
        }
      }
      if (!(yPosition === (this.iterations*2))) {
        if (this.boxBelowComplete(yPosition, xPosition)) {
          this.board[yPosition + 1][xPosition].color = this.selectColor();
          this.addToScore()
          scored++;
          changeTurn = false;
        }
      }
    }

    if (type === "YLine") {
      if (!(xPosition === 0)) {
        if (this.boxLeftComplete(yPosition, xPosition)) {
          this.board[yPosition][xPosition-1].color = this.selectColor();
          this.addToScore();
          scored++
          changeTurn = false;
        }
      }
      if (!(xPosition === (this.iterations*2))) {
        if (this.boxRightComplete(yPosition, xPosition)) {
          this.board[yPosition][xPosition+1].color = this.selectColor();
          this.addToScore()
          scored++
          changeTurn = false;
        }
      }
    }
    if (scored > 0) {
      this.props.updateScore(this.playerOneScore, this.playerTwoScore);
    }
    return changeTurn;
  }

  selectColor() {
    if (this.props.clientsTurn) {
      return (this.props.thisPlayer === "Player One" ? "red" : "blue");
    } else {
      return (this.props.thisPlayer === "Player One" ? "blue" : "red");
    }
  }

  addToScore() {
    if ((this.props.clientsTurn && this.props.thisPlayer === "Player One") ||
         !this.props.clientsTurn && this.props.thisPlayer === "Player Two") {
      this.playerOneScore++;
    } else {
      this.playerTwoScore++;
    }

    // Trigger a Win when all boxes are filled
    if (this.playerOneScore + this.playerTwoScore === (this.iterations * this.iterations)){
      this.gameFinished = true;
      if (this.playerOneScore > this.playerTwoScore) {
        this.props.triggerEnd("Player One");
      } else {
        this.props.triggerEnd("Player Two");
      }

      // Remove the last played indicator
      this.board[this.lastClicked[0]][this.lastClicked[1]] = {
        ...this.board[this.lastClicked[0]][this.lastClicked[1]],
        color: 'black'
      }
    }
  }

  boxAboveComplete(yPosition, xPosition) {
    if (!this.board[yPosition-2][xPosition].isClicked) {
      return false
    }
    if (!this.board[yPosition-1][xPosition-1].isClicked) {
      return false
    }
    if (!this.board[yPosition-1][xPosition+1].isClicked) {
      return false
    }

    return true;
  }

  boxAboveGiven(yPosition, xPosition) {
    let nearbyClicked = 0
    if (this.board[yPosition-2][xPosition].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition-1][xPosition-1].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition-1][xPosition+1].isClicked) {
      nearbyClicked++
    }

    let boxGiven = (nearbyClicked === 2 ? true : false)
    return boxGiven;
  }

  boxBelowComplete(yPosition, xPosition) {
    if (!this.board[yPosition+2][xPosition].isClicked) {
      return false
    }
    if (!this.board[yPosition+1][xPosition-1].isClicked) {
      return false
    }
    if (!this.board[yPosition+1][xPosition+1].isClicked) {
      return false
    }

    return true;
  }

  boxBelowGiven(yPosition, xPosition) {
    let nearbyClicked = 0
    if (this.board[yPosition+2][xPosition].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition+1][xPosition-1].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition+1][xPosition+1].isClicked) {
      nearbyClicked++
    }

    let boxGiven = (nearbyClicked === 2 ? true : false)
    return boxGiven;
  }

  boxLeftComplete(yPosition, xPosition) {
    if (!this.board[yPosition][xPosition-2].isClicked) {
      return false
    }
    if (!this.board[yPosition-1][xPosition-1].isClicked) {
      return false
    }
    if (!this.board[yPosition+1][xPosition-1].isClicked) {
      return false
    }

    return true;
  }

  boxLeftGiven(yPosition, xPosition) {
    let nearbyClicked = 0
    if (this.board[yPosition][xPosition-2].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition-1][xPosition-1].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition+1][xPosition-1].isClicked) {
      nearbyClicked++
    }

    let boxGiven = (nearbyClicked === 2 ? true : false)
    return boxGiven;
  }

  boxRightComplete(yPosition, xPosition) {
    if (!this.board[yPosition][xPosition+2].isClicked) {
      return false
    }
    if (!this.board[yPosition-1][xPosition+1].isClicked) {
      return false
    }
    if (!this.board[yPosition+1][xPosition+1].isClicked) {
      return false
    }

    return true;
  }

  boxRightGiven(yPosition, xPosition) {
    let nearbyClicked = 0
    if (this.board[yPosition][xPosition+2].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition-1][xPosition+1].isClicked) {
      nearbyClicked++
    }
    if (this.board[yPosition+1][xPosition+1].isClicked) {
      nearbyClicked++
    }

    let boxGiven = (nearbyClicked === 2 ? true : false)
    return boxGiven;
  }

  aiTurn() {
    if (this.aisTurn && !this.gameFinished) {
      let validLine = false;
      let randomLine;
      let maxLoops = 10;
      let currentLoops = 0;
      while (!validLine && currentLoops < maxLoops) {
        randomLine = this.getRandomValidLine();
        if (randomLine[0] === "Valid") {
          validLine = true;
        }
        currentLoops++;
      }
      this.handleClick("N/A", randomLine[1], randomLine[2], randomLine[3], true)
    }
  }

  getRandomValidLine() {
    // First check if the last move gave a box
    let potentialLine;
    if (this.beforeLastClicked) {
      potentialLine = this.checkNearbyBoxesForCompletion(this.beforeLastClicked[0], this.beforeLastClicked[1]);
      if (potentialLine[0] != "Invalid") {
        return potentialLine;
      }
    }
    potentialLine = this.checkNearbyBoxesForCompletion(this.lastClicked[0], this.lastClicked[1]);
    if (potentialLine[0] != "Invalid") {
      return potentialLine;
    }

    // Make a list of moves that complete a box or do not offer the other player a box,
    // then choose one of those at random
    let potentialAIMoves = [];
    potentialAIMoves = this.getSmartMoves();
    if (potentialAIMoves.length > 0) {
      return potentialAIMoves[Math.floor(Math.random() * potentialAIMoves.length)]
    }

    /*
      TODO implement sicko mode
      Once all smart moves are gone, find the shortest chain,
      if all chains are greater than >2 flip the sicko mode switch
      in sicko mode, the bot will first create a list of all the boxes that
      will be complete (based on the opponents last move), then removes the
      2nd last move (leaving a 2 box chain the opponent must take)

      in cases of loops this may lead to a dead end, thus the ai will have to
      remove more than just the 2nd last move 
    */


    // Pick a random element of the board
    const randomXIndex = Math.floor(Math.random() * (this.iterations*2+1));
    const randomYIndex = Math.floor(Math.random() * (this.iterations*2+1));
    // Final backup in case I missed something
    return this.getTrulyRandomLine(randomXIndex, randomYIndex)
  }

  // This function checks all boxes to see if any line will complete a box
  // if no line completes a box it returns lines that will not leave boxes 1 away
  // from completion
  getSmartMoves() {
    let willCompleteBox = false;
    let willGiveBox = false;
    let smartMoves = [];
    let boxAboveComplete = false;
    let boxAboveGiven = false;
    let boxBelowComplete = false;
    let boxBelowGiven = false;
    let boxLeftComplete = false;
    let boxLeftGiven = false;
    let boxRightComplete = false;
    let boxRightGiven = false;

    for(let i = 0; i <= (2*this.iterations); i=i+2) {
      for (let j = 1; j <= (2*this.iterations-1); j=j+2) {
        if (!this.board[i][j].isClicked) {
          if (i !== 0) {
            boxAboveComplete = this.boxAboveComplete(i, j);
            boxAboveGiven = this.boxAboveGiven(i,j);
          }
          if (i !== (2*this.iterations)) {
            boxBelowComplete = this.boxBelowComplete(i, j);
            boxBelowGiven = this.boxBelowGiven(i, j);
          }
          if (boxAboveComplete || boxBelowComplete) {
            return [["Valid", i, j, "XLine"]];
          } else {
            if (!(boxAboveGiven || boxBelowGiven)) {
              smartMoves.push(["Valid", i, j, "XLine"]);
            }
          }
        }
      }
    }

    for(let i = 1; i <= (2*this.iterations-1); i=i+2) {
      for (let j = 0; j <= (2*this.iterations); j=j+2) {
        if (!this.board[i][j].isClicked) {
          if (j !== 0) {
            boxLeftComplete = this.boxLeftComplete(i, j);
            boxLeftGiven = this.boxLeftGiven(i,j);
          }
          if (j !== (2*this.iterations)) {
            boxRightComplete = this.boxRightComplete(i, j);
            boxRightGiven = this.boxRightGiven(i, j);
          }
          if (boxLeftComplete || boxRightComplete) {
            return [["Valid", i, j, "YLine"]];
          } else {
            if (!(boxLeftGiven || boxRightGiven)) {
              smartMoves.push(["Valid", i, j, "YLine"]);
            }
          }
        }
      }
    }

    return smartMoves;

  }

  getTrulyRandomLine(randomXIndex, randomYIndex) {
    // Search Down and Right from the random element
    for (let i=randomYIndex; i <= (this.iterations*2); i++) {
      for (let j=randomXIndex; j <= (this.iterations*2); j++) {
        if (this.board[i][j].type === "XLine" || this.board[i][j].type === "YLine") {
          if (!this.board[i][j].isClicked){
            return ["Valid", i, j, this.board[i][j].type];
          }
        }
      }
    }

    // Search Up and right from the random element
    for (let i=randomYIndex; i >= 0; i--) {
      for (let j=randomXIndex; j <= (this.iterations*2); j++) {
        if (this.board[i][j].type === "XLine" || this.board[i][j].type === "YLine") {
          if (!this.board[i][j].isClicked){
            return ["Valid", i, j, this.board[i][j].type];
          }
        }
      }
    }

    // Search down and left from the random element
    for (let i=randomYIndex; i <= (this.iterations*2); i++) {
      for (let j=randomXIndex; j >= 0; j--) {
        if (this.board[i][j].type === "XLine" || this.board[i][j].type === "YLine") {
          if (!this.board[i][j].isClicked){
            return ["Valid", i, j, this.board[i][j].type];
          }
        }
      }
    }

    // Search up and left from the random element
    for (let i=randomYIndex; i >= 0; i--) {
      for (let j=randomXIndex; j >= 0; j--) {
        if (this.board[i][j].type === "XLine" || this.board[i][j].type === "YLine") {
          if (!this.board[i][j].isClicked){
            return ["Valid", i, j, this.board[i][j].type];
          }
        }
      }
    }
    return ["Invalid", null, null, null];
  }

  checkNearbyBoxesForCompletion(lastClickedY, lastClickedX) {
    // First Check to see if the last line left a box open to Close
    let linesLeft = 3;
    let potentialLine = {};

    // Check box above if possible
    if ((this.board[lastClickedY][lastClickedX].type == "XLine") &&
        lastClickedY != 0) {
          if (this.board[lastClickedY-2][lastClickedX].isClicked) {
            linesLeft--;
          } else {
            potentialLine = {y:lastClickedY-2, x:lastClickedX};
          }

          if (this.board[lastClickedY-1][lastClickedX-1].isClicked) {
            linesLeft--;
          } else {
            potentialLine = {y:lastClickedY-1, x:lastClickedX-1};
          }

          if (this.board[lastClickedY-1][lastClickedX+1].isClicked) {
            linesLeft--;
          } else {
            potentialLine = {y:lastClickedY-1, x:lastClickedX+1};
          }

          if (linesLeft === 1) {
            return (["Valid",
                    potentialLine.y,
                    potentialLine.x,
                    this.board[potentialLine.y][potentialLine.x].type])
          }
        }

    // Check box below if possible
    linesLeft = 3;
    if ((this.board[lastClickedY][lastClickedX].type == "XLine") &&
        lastClickedY != this.iterations*2) {
          if (this.board[lastClickedY+2][lastClickedX].isClicked) {
            linesLeft--;
          } else {
            potentialLine = {y:lastClickedY+2, x:lastClickedX};
          }

          if (this.board[lastClickedY+1][lastClickedX-1].isClicked) {
            linesLeft--;
          } else {
            potentialLine = {y:lastClickedY+1, x:lastClickedX-1};
          }

          if (this.board[lastClickedY+1][lastClickedX+1].isClicked) {
            linesLeft--;
          } else {
            potentialLine = {y:lastClickedY+1, x:lastClickedX+1};
          }

          if (linesLeft === 1) {
            return (["Valid",
                    potentialLine.y,
                    potentialLine.x,
                    this.board[potentialLine.y][potentialLine.x].type])
          }
        }

    // Check box to the left if possible
    linesLeft = 3;
    if ((this.board[lastClickedY][lastClickedX].type == "YLine") &&
          lastClickedX != 0) {
        if (this.board[lastClickedY][lastClickedX-2].isClicked) {
          linesLeft--;
        } else {
          potentialLine = {y:lastClickedY, x:lastClickedX-2};
        }

        if (this.board[lastClickedY+1][lastClickedX-1].isClicked) {
          linesLeft--;
        } else {
          potentialLine = {y:lastClickedY+1, x:lastClickedX-1};
        }
        if (this.board[lastClickedY-1][lastClickedX-1].isClicked) {
          linesLeft--;
        } else {
          potentialLine = {y:lastClickedY-1, x:lastClickedX-1};
        }

        if (linesLeft === 1) {
          return (["Valid",
                  potentialLine.y,
                  potentialLine.x,
                  this.board[potentialLine.y][potentialLine.x].type])
      }
    }

    // Check box to the right if possible
    linesLeft = 3
    if ((this.board[lastClickedY][lastClickedX].type == "YLine") &&
          lastClickedX != this.iterations*2) {
        if (this.board[lastClickedY][lastClickedX+2].isClicked) {
          linesLeft--;
        } else {
          potentialLine = {y:lastClickedY, x:lastClickedX+2};
        }

        if (this.board[lastClickedY+1][lastClickedX+1].isClicked) {
          linesLeft--;
        } else {
          potentialLine = {y:lastClickedY+1, x:lastClickedX+1};
        }
        if (this.board[lastClickedY-1][lastClickedX+1].isClicked) {
          linesLeft--;
        } else {
          potentialLine = {y:lastClickedY-1, x:lastClickedX+1};
        }

        if (linesLeft === 1) {
          return (["Valid",
                  potentialLine.y,
                  potentialLine.x,
                  this.board[potentialLine.y][potentialLine.x].type])
      }
    }
    return ["Invalid", null, null, null]
  }

  render() {
    return (
      <div className="GameBoard">
      {this.board.map(row => (
        this.convertRow(row)
      ))}
      </div>
    )
  }
}
