import React from 'react';
import './Chatbox.css'

export class ChatBox extends React.Component {
  constructor(props) {
    super(props);
    this.messages = (this.props.againstAI ?
                    [{user: "Server", message: "Welcome to DotsNBoxes. Each player draws a line looking to make a 1 by 1 box. If you make a box, you go again. Enjoy!"}]
                    : [{user: "Server", message: "Welcome to DotsNBoxes. Each player draws a line looking to make a 1 by 1 box. If you make a box, you go again. Enjoy!"}, {user: "Server", message: "Please wait while we connect you to an opponent"}]);
    this.state = {};
    if (this.props.webSocket !== null) {
      this.props.webSocket.addEventListener("message", (msg) => {
        const message = JSON.parse(msg.data);
        if (message.type === "Chat Message") {
          this.messages.push({user:this.props.otherUser, message: message.message})
          this.setState({});
          setTimeout(() => {
            let chatWindow = document.getElementById("ChatWindow");
            chatWindow.scrollTo(0, chatWindow.scrollHeight);
          }, 10)
        }
        if (message.type === "Game Found") {
          this.messages.push({user: "Server", message: "Game found, enjoy!"})
        }
        if (message.type === "Opponent Disconnected") {
          this.messages.push({user: "Server", message: "Your opponent has disconnected"})
          this.setState({});
        }
      });
    }

    this.sendMessage = this.sendMessage.bind(this);
  }

  sendMessage(event) {
    if (event.key === "Enter") {
      this.messages.push({
        user: "You",
        message: event.target.value
      });
      setTimeout(() => {
        let chatWindow = document.getElementById("ChatWindow");
        chatWindow.scrollTo(0, chatWindow.scrollHeight);
      }, 10)
      if (this.props.webSocket !== null && this.props.webSocket.readyState === WebSocket.OPEN) {
        this.props.webSocket.send(JSON.stringify({
          type: "Chat Message",
          gameId: this.props.gameId,
          message: event.target.value
        }))
      }
      event.target.value = ""
    }

    this.setState({});
  }

  displayMessage(message) {
    return <p><strong>{message.user}:</strong> {message.message}</p>
  }

  render() {
    return (
      <div className="ChatBox">
        <div className="BoardHeader"><h3>Chat</h3></div>
        <div id="ChatWindow">
          {this.messages.map(message => (
            this.displayMessage(message)
          ))}
        </div>
        <div className="ChatEntry">
          <input type='text' placeholder="Chat" onKeyDown={this.sendMessage}></input>
        </div>
      </div>
    );
  }
}
