import React from "react";
import './EndCard.css'

export class EndCard extends React.Component {
  constructor(props) {
    super(props);
    this.className = "EndCardContainer";
    this.close = this.close.bind(this);

    this.state = {}
  }

  close(event) {
    this.className = "hidden";
    this.setState({})
  }

  render() {
    return (
      <div className ={this.className}>
        <div className = "EndCard">
          {this.props.winner ? <h3>Congratulations you won!</h3> : <h3> Better luck next time!</h3>}
          <button className="EndCardClose" onClick={this.close}>
            <h3>Close</h3>
          </button>
        </div>
      </div>
    );
  }
}
