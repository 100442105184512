import React from 'react';
import './QuickBoards.css';
import {EloBoard} from './eloboard/EloBoard';
import {TourneyBoard} from './tourneyboard/TourneyBoard';

export class QuickBoards extends React.Component {
  render() {
    return (
      <div className='Leaderboard'>
        <EloBoard />
        <TourneyBoard />
      </div>
    );
  }
}
