import React from 'react';
import {useLocation} from 'react-router-dom';
import {DotGame} from './dotgame/DotGame';

export function DotGamePage(props) {
  const location = useLocation();
  const gameSettings = location.state

  return(
    <div>
      {window.onbeforeunload = function() {
        return "Leaving this page will cause your game to be lost"
      }}
      <DotGame {...gameSettings} username={props.username} elo={props.elo} role={props.role} setElo={props.setElo}/>
    </div>
  );
}
