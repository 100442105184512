import React from 'react';

export class Dot extends React.Component {

  render() {
    return (
      <div className="Dot"></div>
    )
  }
}
