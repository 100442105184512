import React from 'react';
import './LearnPage.css'

export class LearnPage extends React.Component {
  render() {
    return (
      <div className="LearnPage">
        <div className="LearnBox">
          <div className="LearnText">
            <h1>What is Dots and Boxes?</h1>
            <h3>
              Dots and Boxes is a simple game of wits and strategy easily played
              by any two or more people with paper and a writing utensil
              (Or access to the internet and this snazzy website).
            </h3>
            <h3>
              The rules of the game are simple:
              <ol>
                <li>
                  The board consists of a grid of dots
                </li>
                <li>
                  Players take turns connecting two adjacent dots with a vertical
                  or horizontal line
                </li>
                <li>
                  Should a player close a box (a box being defined with 4 of
                  the dots as its corners and a width of 1 line) that player fills
                  in the box with their color and goes again.
                </li>
                <li>
                  When all possible lines have been drawn the winner is the player
                  with the most boxes filled with their color
                </li>
              </ol>
            </h3>
            <h3>
              These simple rules lead to a deceptively complex game.
            </h3>
            <h1>
            Strategy
            </h1>
            <h3>
              In the first phase of the game players should aim to make moves that
              do not allow the other player to close a box. Throughout this phase
              players are building the "maze" that will constitute the second phase
              of the game.
            </h3>
            <h3>
              Expert players should use this phase to build a maze favorable
              to them as this is the phase that ultimately determines the winner
              when perfect strategy is used in the second phase. For beginning players,
              though, simply not giving the opponent any free boxes should be your goal.
            </h3>
            <h3>
              When the board is in a state where any move a player makes will give boxes
              to the other players the game has entered into the second phase. Here
              players will aim to choose the move that gives the opponent the least
              amount of boxes possible.
            </h3>
            <h3>
              This is most easily achieved by examining the board and identifying
              the "chains" present in the board, identifiable as the maze's individual
              "tunnels". Remember when calculating the length of a chain that intersections
              between two chains will work as the endpoint for the first chain that reaches it.
            </h3>
            <h3>
              There is a certain strategy that, if able to be used by a player, will
              almost certainly guarantee a win against any skill level player.
              This strategy is not immediately apparent and will be left as an exercise
              to the reader to figure out.
            </h3>
            <h3>
              The only hint I will give for this strategy is to note that, sometimes,
              collecting all of the boxes in the chain can be detrimental due to the
              fact that a player MUST go again when they close a box.
            </h3>
            <h3>
              I hope you enjoy figuring out the intricacies of this game!
            </h3>
          </div>
        </div>
      </div>
    )
  }
}
