import React from 'react';
import {Link} from 'react-router-dom';
import './CreateGame.css';

export class CreateGame extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        gameSettings: {
          boardSize: "3x3",
          againstAI: false,
          ranked: false,
          gameId: null
        }
      }
      this.handleSizeChange = this.handleSizeChange.bind(this);
      this.handleAIChange = this.handleAIChange.bind(this);
      this.handleRankedChange = this.handleRankedChange.bind(this);
  }

  handleSizeChange(event) {
    let newSettings = this.state.gameSettings;
    newSettings.boardSize = event.target.value;
    this.setState({gameSettings: newSettings});
  }

  handleAIChange(event) {
    let newSettings = this.state.gameSettings;
    newSettings.againstAI = event.target.checked;
    let rankCheckbox = document.getElementById('ranked');
    if (rankCheckbox.checked === true && event.target.checked === true) {
      rankCheckbox.checked = false;
      newSettings.ranked = false;
    }
    rankCheckbox.disabled = event.target.checked
    this.setState({gameSettings: newSettings});
  }

  handleRankedChange(event) {
    let newSettings = this.state.gameSettings;
    newSettings.ranked = event.target.checked;
    let aiCheckbox = document.getElementById('againstAI');
    if (aiCheckbox.checked === true && event.target.checked === true) {
      aiCheckbox.checked = false;
      newSettings.againstAI = false;
    }
    aiCheckbox.disabled = event.target.checked
    this.setState({gameSettings: newSettings});
  }

  render() {
    return (
      <div className="CreateGame">
        <div className="BoardHeader">
          <h3>Create a Game</h3>
        </div>
        <form>
          <div className="createGameSettings">
            <div className="gameSetting">
              <label htmlFor="BoardSize">Board Size:</label>
              <select name="BoardSize" onChange={this.handleSizeChange}>
                <option value="3x3">3x3</option>
                <option value="5x5">5x5</option>
                <option value="9x9">9x9</option>
                <option value="13x13">13x13</option>
              </select>
            </div>
            <div className="gameSetting">
              <label htmlFor="againstAI">Play against AI?</label>
              <input type="checkbox" name="againstAI" id="againstAI" onChange={this.handleAIChange}></input>
            </div>
            <div className="gameSetting">
              <label htmlFor="ranked">Ranked?</label>
              <input type="checkbox" name="ranked" id="ranked" onChange={this.handleRankedChange}></input>
            </div>
          </div>
          <div>
            <div className="startButton">
              <Link
                to="/dotgame"
                state={this.state.gameSettings}>
                <h3 className="startButtonText">Create Game</h3>
              </Link>
            </div>
          </div>
        </form>
    </div>
    );
  }
}
