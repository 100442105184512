import React from 'react';
import {Link} from 'react-router-dom';
import './QuickPlay.css';

export class QuickPlay extends React.Component {
  render() {
    return (
      <div className="GameSelect">

        <div className="QuickPlayHeader">
          <a href="Leaderboards.html"><h2>QuickPlay</h2></a>
        </div>
        <div className="QuickSelectNoCustom">
          <div className="QuickSelectColumn">
            <Link to="/DotGame" state={{boardSize: "3x3", againstAI: false, ranked: true, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">3x3</h2>
                    <h3 className="QuickSelectText">Ranked</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "5x5", againstAI: false, ranked: true, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">5x5</h2>
                    <h3 className="QuickSelectText">Ranked</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "9x9", againstAI: false, ranked: true, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">9x9</h2>
                    <h3 className="QuickSelectText">Ranked</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "13x13", againstAI: false, ranked: true, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">13x13</h2>
                    <h3 className="QuickSelectText">Ranked</h3>
                </div>
            </Link>
          </div>
          <div className="QuickSelectColumn">
            <Link to="/DotGame" state={{boardSize: "3x3", againstAI: false, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">3x3</h2>
                    <h3 className="QuickSelectText">Unranked</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "5x5", againstAI: false, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">5x5</h2>
                    <h3 className="QuickSelectText">Unranked</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "9x9", againstAI: false, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">9x9</h2>
                    <h3 className="QuickSelectText">Unranked</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "13x13", againstAI: false, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">13x13</h2>
                    <h3 className="QuickSelectText">Unranked</h3>
                </div>
            </Link>
          </div>
          <div className="QuickSelectColumn">
            <Link to="/DotGame" state={{boardSize: "3x3", againstAI: true, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">3x3</h2>
                    <h3 className="QuickSelectText">vs AI</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "5x5", againstAI: true, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">5x5</h2>
                    <h3 className="QuickSelectText">vs AI</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "9x9", againstAI: true, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">9x9</h2>
                    <h3 className="QuickSelectText">vs AI</h3>
                </div>
            </Link>

            <Link to="/DotGame" state={{boardSize: "13x13", againstAI: true, ranked: false, gameId: null}}>
                <div className="QuickSelectBox">
                    <h2 className="QuickSelectText">13x13</h2>
                    <h3 className="QuickSelectText">vs AI</h3>
                </div>
            </Link>
          </div>
        </div>

        <a className="pagelink" href="/play">
            <div className="QuickSelectBox">
                <h2 className="QuickSelectText">Custom</h2>
            </div>
        </a>
    </div>
    )
  }
}
