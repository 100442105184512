import React from 'react';
import axios from 'axios';

export class EloBoard extends React.Component {
  constructor(props) {
    super(props);
    this.topPlayers = [[1, "test", 2000, "player"]];
    this.state = {};

    axios({
      method: 'get',
      url: "https://dotsnboxes.com/api/top_players.php",
      headers: { 'content-type': 'application/json'},
    }).then(response => {
      this.topPlayers = response.data.TopPlayers;
      this.setState({});
    })
  }

  convertEntry(entry) {
    return (
      <div className={entry[0] % 2 === 1 ? "EloEntry Odd" : "EloEntry Even"}>
        <h3>{entry[0]}</h3>
        <h3 className={entry[3] === "Admin" ? "DarkOrange" : ""}>{entry[1]}</h3>
        <h3>{entry[2]}</h3>
      </div>
    )
  }

  render() {
    return (
      <div className="BothBoards" id="Leaderboard1">
        <div className="BoardHeader">
          <a href="/Leaderboards"><h3>Top 10 Players</h3></a>
        </div>
        <div className="BoardContents">
          <div className="EloEntry Even EloDescriptor">
              <h3 className="">Rank</h3>
              <h3 className="">Name</h3>
              <h3 className="">Elo</h3>
          </div>
          {this.topPlayers && this.topPlayers.map(entry => (this.convertEntry(entry)))}
        </div>
      </div>
    );
  }
}
