import React from 'react';


export class TourneyBoard extends React.Component {
  render() {
    return (
      <div className="BothBoards">
        <div className="BoardHeader">
            <a href="/Leaderboards"><h3>Tournament Results</h3></a>
        </div>
        <div className="BoardContents">
          <div className="TourneyName"><h3>First Menardian 20x20</h3></div>
          <div className="TourneyEntry">
              <h3 className="Winner DarkOrange">The Creator</h3>
              <h3 className="Result">5 to 4</h3>
              <h3 className="Loser">Amber</h3>
          </div>
        </div>
      </div>
    );
  }
}
