import React from 'react';

export class Box extends React.Component {

  render() {
    return (
      <div className="Box" style={{backgroundColor: this.props.color}}></div>
    );
  }
}
