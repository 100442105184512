import './App.css';
import {BrowserRouter as Router,
        Routes,
        Route,
      } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import {Navbar} from '../navbar/Navbar';
import {HomePage} from '../homepage/HomePage';
import {PlayPage} from '../playpage/PlayPage';
import {DotGamePage} from '../dotgamepage/DotGamePage';
import {LearnPage} from '../learnpage/LearnPage';
import {LeaderboardsPage} from '../leaderboardspage/LeaderboardsPage';
import {LoginPage} from '../loginpage/LoginPage';

export class App extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        username: window.localStorage.getItem("USERNAME"),
        role: window.localStorage.getItem("ROLE"),
        elo: window.localStorage.getItem("ELO")
      }

      this.setAccount = this.setAccount.bind(this);
      this.setElo = this.setElo.bind(this);
      this.clearStorage = this.clearStorage.bind(this);
  }

  setAccount(username, role, elo) {
    window.localStorage.setItem("USERNAME", username);
    window.localStorage.setItem("ROLE", role);
    window.localStorage.setItem("ELO", elo);
  }

  setElo(elo) {
    window.localStorage.setItem("ELO", elo);
  }

  clearStorage() {
    window.localStorage.clear();
  }


  render() {
    return (
      <Router>
        <div className="App">
          <Navbar username={this.state.username} clearStorage={this.clearStorage}/>
          <Routes>
            <Route exact path='/' element=<HomePage />></Route>
            <Route exact path='/play' element=<PlayPage />></Route>
            <Route exact path='/dotgame' element=<DotGamePage username={this.state.username} elo={this.state.elo} role={this.state.role} setElo={this.setElo}/>></Route>
            <Route exact path='/learn' element=<LearnPage />></Route>
            <Route exact path='/leaderboards' element=<LeaderboardsPage />></Route>
            <Route exact path='/login' element=<LoginPage setAccount={this.setAccount}/>></Route>
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
