import React from 'react';
import {QuickPlay} from './quickplay/QuickPlay';
import {QuickBoards} from './quickboards/QuickBoards';

export class HomePage extends React.Component {
  render() {
    return (
      <div>
        <QuickPlay />
        <QuickBoards />
      </div>
    )
  }
}
