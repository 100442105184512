import React from 'react';
import {UserCard} from './usercard/UserCard';
import {GameBoard} from './gameboard/GameBoard';
import {ChatBox} from './chatbox/ChatBox';
import {Score} from './score/Score';
import {EndCard} from './endcard/EndCard';
import axios from 'axios';
import './DotGame.css';


function probability(rating1, rating2) {
  return (
    (1.0 * 1.0) / (1 + 1.0 * Math.pow(10, (1.0 * (rating1 - rating2)) / 400))
  );
}

function eloRating(rating1, rating2, constant, win) {

  let prob = probability(rating2, rating1);

  if (win) {
    rating1 = rating1 + constant * (1 - prob);
  } else {
    rating1 = rating1 + constant * (0 - prob);
  }

  return (Math.round(rating1));
}


export class DotGame extends React.Component {
  constructor(props) {
    super(props);
    this.settings = {
      boardSize: this.props.boardSize,
      againstAI: this.props.againstAI,
      ranked: this.props.ranked,
      gameId: this.props.gameId
    }

    this.webSocket = null;
    this.playerOneScore = 0;
    this.playerTwoScore = 0;
    this.gameFinished = false;
    this.winner = false;
    this.foundGame = false;
    this.thisPlayer = "Player One";
    this.otherUser = this.settings.againstAI ? "The Terminator" : "Connecting...";
    this.otherUserElo = this.settings.againstAI ? "2500" : "N/A";
    this.otherUserRole = this.settings.againstAI ? "Admin" : "Anonymous";
    this.state = {clientsTurn: this.settings.againstAI ? true : false}

    if (!this.props.againstAI) {
      this.webSocket = new WebSocket((process.env.NODE_ENV === 'development' ? "ws://localhost:3001" : "wss://dotsnboxes.com:8080"))

      this.webSocket.onopen = () => {
        let connectionRequest = {type: "Find Game",
                                 boardSize: this.settings.boardSize,
                                 ranked: this.settings.ranked,
                                 gameId: this.settings.gameId,
                                 user: (this.props.username != null ? this.props.username : "Anonymous"),
                                 role: (this.props.role != null ? this.props.role : "Anonymous"),
                                 elo: (this.props.elo != null ? this.props.elo : "1000")};
        this.webSocket.send(JSON.stringify(connectionRequest));
      }

      this.webSocket.addEventListener("message", (msg)=>{
        const message = JSON.parse(msg.data);
        if (message.type === "Game Found") {
          if (message.playerOne === "You") {
            this.thisPlayer = "Player One";
            this.otherUser = message.playerTwo;
            this.otherUserElo = message.elo;
            this.otherUserRole = message.role;
          } else {
            this.thisPlayer = "Player Two"
            this.otherUser = message.playerOne;
            this.otherUserElo = message.elo;
            this.otherUserRole = message.role;
          }
          this.settings.gameId = message.gameId;
          this.foundGame = true;
          this.setState({...this.state});
        }
      })
    }
    this.updateScore = this.updateScore.bind(this);
    this.triggerEnd = this.triggerEnd.bind(this);
    this.swapTurn = this.swapTurn.bind(this);
    }

  componentWillUnmount() {
    if (this.webSocket !== null) {
      this.webSocket.close();
    }
  }

  swapTurn() {
    this.setState({...this.state, clientsTurn: !this.state.clientsTurn})
  }

  updateScore(playerOneScore, playerTwoScore) {
    this.playerOneScore = playerOneScore;
    this.playerTwoScore = playerTwoScore;
    this.setState({
      ...this.state
    })
  }

  triggerEnd(winner) {
    this.gameFinished = true;
    this.winner = winner === this.thisPlayer ? true : false
    let newRating;
    if ((this.props.username != null && !this.settings.againstAI) && this.settings.ranked) {
      newRating = eloRating(parseInt(this.props.elo), parseInt(this.otherUserElo), 30, this.winner);
      this.props.setElo(newRating);
      axios({
        method: 'post',
        url: "https://dotsnboxes.com/api/update_elo.php",
        headers: { 'content-type': 'application/json'},
        data: {username: this.props.username, newElo: newRating}
      }).then(response => {
      })
    }
  }

  render() {
    return (

      <div className="DotGameContainer">
        {this.gameFinished ? <EndCard winner={this.winner}/> : <div className="failed"></div>}
        <div className="GamePart">
          <div className="ScoreAndUsers">
            <UserCard
                side="Left"
                username={this.thisPlayer === "Player One" ? this.props.username : this.otherUser}
                clientsTurn={this.state.clientsTurn}
                thisPlayer={this.thisPlayer}
                elo={this.thisPlayer === "Player One" ? this.props.elo : this.otherUserElo}
                role={this.thisPlayer === "Player One" ? this.props.role : this.otherUserRole}/>
            <Score p1Score={this.playerOneScore} p2Score={this.playerTwoScore}/>
            <UserCard
                side="Right"
                username={this.thisPlayer === "Player Two" ? this.props.username : this.otherUser}
                clientsTurn={this.state.clientsTurn}
                thisPlayer={this.thisPlayer}
                elo={this.thisPlayer === "Player Two" ? this.props.elo : this.otherUserElo}
                role={this.thisPlayer === "Player Two" ? this.props.role : this.otherUserRole}/>
          </div>
          <GameBoard
            {...this.settings}
            updateScore={this.updateScore}
            thisPlayer={this.thisPlayer}
            triggerEnd={this.triggerEnd}
            webSocket={this.webSocket}
            gameId={this.settings.gameId}
            clientsTurn={this.state.clientsTurn}
            swapTurn={this.swapTurn}
            />
        </div>
        <div className="ChatPart">
          <ChatBox
            againstAI={this.settings.againstAI}
            webSocket={this.webSocket}
            gameId={this.settings.gameId}
            otherUser={this.otherUser}/>
        </div>
      </div>
    );
  }
}
